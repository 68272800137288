import BasePlayScene from '../BaseFinder/PlayScene'

export default class PlayScene extends BasePlayScene {
  preload () {
    this.load.image('bg', '/static/games/game4/bg.jpg');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game4/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.image('bg_anim', '/static/games/game4/bg_noanim.png');
    }
    
    this.load.image('dropzone', '/static/games/game4/dropzone.png');
    this.load.audio('bg_audio', '/static/games/game4/bg.mp3');

    this.corrects = [
      { name:'4-1.png', x:47, y: 809, drop_x:370, drop_y:790, width: Math.floor(486/3), height: 88, drop_scale: 0.7,title:"ถุงยางอนามัย" },
      { name:'4-2.png', x:41, y:181, drop_x:981, drop_y:772, width:  Math.floor(147/3), height: 133, drop_scale: 0.65,title:"เจลหล่อลื่น" },
      { name:'4-3.png', x:131, y:419, drop_x:558, drop_y:775, width:  Math.ceil(339/3), height: 123, drop_scale: 0.66,title:"ยาต้านไวรัส PrEP" },
      { name:'4-4.png', x:1177, y:224, drop_x:870, drop_y:776, width: Math.floor(363/3), height: 101, drop_scale: 0.80,title:"ถุงสำลี" },
      { name:'4-5.png', x:1405, y:341, drop_x:796, drop_y:772, width:  Math.floor(216/3), height: 126, drop_scale: 0.68,title:"แอลกอฮอล์" },
      { name:'4-6.png', x:791, y:651, drop_x:655, drop_y:785, width:  Math.floor(609/3), height: 94, drop_scale: 0.65,title:"เข็มฉีดยาสะอาด" },
      { name:'4-7.png', x:518, y:394, drop_x:486, drop_y:778, width:  Math.floor(372/3), height: 133, drop_scale: 0.6,title:"ชุดอุปกรณ์สะอาด" }
    ]

    this.wrongs = [
      { name:'4-8.png', x:994, y:617, width: Math.floor(438/3), height: 170 },
      { name:'4-9.png', x:50, y:413, width: Math.floor(255/3), height: 129 },
      { name:'4-10.png', x:87, y:194, width: Math.floor(183/3), height: 123 },
      { name:'4-11.png', x:654, y:479, width: Math.floor(453/3), height: 41 },
      { name:'4-12.png', x:714, y:693, width: Math.floor(423/3), height: 63 },
      { name:'4-13.png', x:1441, y:201, width: Math.floor(294/3), height: 105 },
      { name:'4-14.png', x:1341, y:99, width: Math.floor(414/3), height: 85 },
      { name:'4-15.png', x:1357, y:711, width: Math.floor(750/3), height: 159 },
      { name:'4-16.png', x:1183, y:153, width: Math.floor(180/3), height: 51 },
      { name:'4-17.png', x:1183, y:384, width: Math.floor(435/3), height: 78 },
    ]

    this.corrects.forEach((item,index)=>{
      this.load.spritesheet('correct-'+index, '/static/games/game4/objects/correct/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    this.wrongs.forEach((item,index)=>{
      this.load.spritesheet('wrong-'+index, '/static/games/game4/objects/wrong/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    super.preload();
  }

  init(data) {
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.total_score = 7
    this.dropzone_w = 753
  }
}
